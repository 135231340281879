.landing-container {
  width: 100vw;
  height: 100vh;
}

.landing-content {
  width: 60%;
  margin: auto;
  padding-top: 10rem;
  padding-bottom: 10rem;
  margin-top: 15vh;
}

.landing-text {
  width: 80%;
}

.logo-container {
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
}

// // Media Queries

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .landing-content {
    width: 90%;
    padding-top: 5rem;
    margin-top: 5vh;
  }
}

// Small - Medium devices
@media (min-width: 600px) and (max-width: 634px) {
  .landing-content {
    width: 85%;
  }
}

// Small - Medium devices
@media (min-width: 635px) and (max-width: 819px) {
  .landing-content {
    width: 85%;
  }
}

// Big phones and Small Tablets
@media (min-width: 820px) and (max-width: 944px) {
  .landing-content {
    width: 85%;
  }
}

// Tablets  and small screens
@media (min-width: 945px) and (max-width: 1199px) {
  .landing-content {
    width: 85%;
  }
}

// Tablets Landscape and Medium  Screens
@media (min-width: 1200px) and (max-width: 1440px) {
  .landing-content {
    width: 85%;
  }
  .landing-text {
    width: 70%;
  }
}

// Large - Extra Large Screens
@media (min-width: 1441px) and (max-width: 1920px) {
  .landing-content {
    width: 75%;
  }
  .landing-text {
    width: 50%;
  }
}
